html, body, #root{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  overflow: hidden ;
}

html{
  font-size: 18px;
  background-color: var(--lightColor);
  overflow-x: hidden;
  --mainColor: #364156; 
  --secondColor: #7D4E57; 
  --lightColor: #F1F0EC; 
  --bubbleColor: #364156

}
.App {
  text-align: center;
  background-color: var(--lightColor);
  height: 100vh;
  width: 100%;
  max-width: 100%;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.App-header {
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--lightColor);
  min-height: 10vh;
  min-width: 100% ;
}

.App-footer {
  background-color: var(--lightColor);
  min-height: 5vh;
  color: var(--mainColor);
  display:flex;
  width: 90vw;
  font-family: 'Overpass', sans-serif;
  height: 4px;
  font-size: .8rem;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding: 5vw 5vw;
}
.left {
  margin-top: 40px;
  height : 10vh;
  margin-left :5vw;
  margin-bottom: 40px;
  position: absolute;
  display:flex;
  align-items: center;
  left: 0;
}

.right {
  margin-top: 40px;
  margin-bottom: 40px;
  height : 10vh;

  margin-right:5vw;
  direction: rtl;
  display:flex;
  align-items: center;
  position: absolute;
  white-space: nowrap;
  right: 0;
}

.header-wrap {
  position:fixed;
}

.header-link, .header-link:visited {
  text-decoration: none;
  font-family: 'Overpass', sans-serif;
  height: 4px;
  font-size: .8rem;
  color: var(--mainColor);
}

.header-link:after, .header-link:visited:after {
  content: '';
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index:1;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/img/underline.svg");
  transition: 0.3s;
}

.header-link::before{
  content:'';
  width:100%;
  height: 5px;
  transition:.3s;
  position: absolute;
  bottom:0;
  right: 0;
  z-index: 2;
  background-color: var(--lightColor);
}

.header-link:hover::before{
  width: 0%;
}
.header-link:hover:after, .header-link:visited:hover:after {
  width: 100%;
  height:4px;
}

.header-link.block, .header-link:visited.block {
  display: block;
}

.header-link[page="fixed"], .header-link[page="fixed"]:visited {
  text-decoration: none;
  font-family: 'Overpass', sans-serif;
  height: 4px;

  font-size: .8rem;
  color:var(--mainColor);
}

.header-link[page="fixed"]:after, .header-link[page="fixed"]:visited:after {
  content: '';
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/img/underline.svg");
}


.header-link[page="fixed"]::before, .header-link[page="fixed"]:visited::before {
  width:0%;
}
.link-wrap {
  position: relative;
  margin-left: 20px;
}

.link, .link:visited {
  text-decoration: none;
  font-family: 'Overpass', sans-serif;
  height: 4px;
  font-size: .8rem;
  color:var(--mainColor);
}

.link:after, .link:visited:after {
  content: '';
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/img/underline.svg");
  transition: 0.3s;
}
.link:hover:after, .link:visited:hover:after {
  width: 100%;
  height:4px;
}

.link::before{
  content:'';
  width:100%;
  height: 5px;
  transition:.3s;
  position: absolute;
  bottom:0;
  right: 0;
  z-index: 2;
  background-color: var(--lightColor);
}

.link:hover::before{
  width: 0%;
}

.link.block, .link:visited.block {
  display: block;
}

.tag {
  text-decoration: none;
  font-family: 'Overpass', sans-serif;
  color: var(--mainColor);
  font-size: .8rem;
}

.footer-link {
  display:flex;
  flex-direction: row;
  direction: rtl;
}
.page {
  display:flex;
  width: 100vw;
  flex-direction: column;
  background-color: var(--lightColor);
}

.name-wrap {
  display:flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.name {
  font-family: 'Cormorant Garamond', serif;
  position:absolute;
  z-index: 0;
  color:var(--mainColor);
  font-size: 4rem;
  pointer-events: none;

}

.flipped {
  transform: rotate(180deg);
}


.menu-link-wrap{
  display:flex;
  flex-direction: column;
  margin-top: 60px;
  height:100vh;
  font-size: 4rem;
  align-items: center;
}

.menu-link{
  color: var(--lightColor);
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.75rem;
  text-decoration: none;
  margin: 0px;
  margin-top: 50px;
}

.menu-footer{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40vw;
  margin-top: 200px;
  height: 90vh;
  align-items: center;
}

.mf-link{
  color: var(--lightColor);
  font-family: 'Overpass', sans-serif;
  font-size: .75rem;
  text-decoration: none;
}

.mountains {
  margin-bottom: -30px;
  margin-left: -400px;
  width: 300vw;
  display:block;
  z-index: 1;
}

.content {
  z-index:1 ;
  background-color: var(--mainColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.work {
  display: flex;
  flex-direction: column ;
  text-align: left;
  margin-left: 60px;
  margin-top: 90px;
  margin-bottom: 150px;
}

.work-header{
  color: var(--lightColor);
  font-family: 'Overpass', sans-serif;
  font-size: 1.25rem;
  margin-bottom: 100px;
}

.work-title{
  color: var(--lightColor);
  transition: all .4s;
}


.work-title a{
  color: var(--lightColor);
  transition: all .4s;
}

@keyframes slide {
  from { transform : translateY(0em); scale: 1; }
  to   { transform : translateY(-45em); scale: .1; } 
}
.work-wrap {
  display:flex;
  flex-direction: row;
  align-items: left;
  width: 65vw;
  justify-content: space-between;
}
.image-wrap{
  position:relative;
}
.work-image {
  position: absolute;
  max-width: 30vw;
  max-height: 70vh;
}

.work-image[state="0"] {
  transform: rotate(-15deg);
  transform-origin: bottom right;
  transition: transform .25s linear;

}
.work-image[state="1"] {
  transform: rotate(-10deg);
  transform-origin: bottom right;
  transition: transform .25s linear;



}
.work-image[state="2"] {
  transform: rotate(-5deg);
  transform-origin: bottom right;
  transition: transform .25s linear;

  
}
.work-image[state="3"] {
  transform: rotate(0);

}

.work-titles {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  height: 80vh;
  color: var(--lightColor);
}
.work-title:hover{
  color: #B89EA3;
  transform: translateX(-5px);
}

.work-title:hover a{
  color: #B89EA3;
  transform: translateX(-5px);
}

.work-title:hover .work-category{
  color: #B89EA3;
  transform: translateX(-10px);
}


.work-image2{
  display:none;
}


.work-category{
  margin-block-start: 0px;
  font-size: .75rem;
  transition: .5s;
  font-family: 'Overpass', sans-serif;
}

.work-link {
  color: var(--lightColor);
  font-family: 'Cormorant Garamond', serif;
  font-size: 2.5rem;
  text-decoration: none;
}

@keyframes slide {
  from { transform : translateY(0em) }
  to   { transform : translateY(-45em) } 
}

.bubble-wrap {
  animation: moveBubble 60s both;
  position:fixed;
  z-index: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.1);
  }
}

@keyframes moveBubble {
  0% {
    opacity: 0;
    transform: translate(0, 100px);
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translate(-75vh, -100vh);
  }
}

.bubble {
  animation: pulse 50s infinite;
  z-index: 0;
  background-color:transparent;
  border-radius: 50%; 
  border: 1.5px solid var(--bubbleColor);
  width :20px;
  height: 20px;
  margin-top: 60vh;
  left: 50vw;
  position: relative;
}

.mountain2{
  display:none;
}

.mountain1{
  display:block;
}

.hamburger{
  display:none;
}


.hamburger2{
  display:none;
}

.menu{
  display: none;
  position: fixed;
  height:100vh;
  background-color:var(--mainColor );
  width:100vw;
  z-index: 1000;
  left:100vw;
  top:0;
  bottom:0;
  right:0;
  transition: .75s;
  overflow: hidden;
}
   
@media (max-width: 1300px){
  .work-link{
    font-size: 2rem;
  }
}

@media (max-width: 1100px){

  .App-header{
    height:5vh;
  }

  .left{
    height: 30px;
  }

  .right{
    height: 30px;
  }

  .name-wrap {
    height: 38vh;
  }

  .work-title{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width:80vw;
    justify-content: space-between;
  }

  .work {
    margin-bottom: 50px;
    margin-left: 0px;
  }
  .mountains{
    width: 900vw;
  }

  .work-wrap {
    flex-direction: row-reverse;
    width: 80vw;
  }
   .work-titles {
     height: 90vh;
     justify-content: space-evenly;
   }
   .work-link{
     font-size: 2rem;
   }
   .work-image2 {
     display: block;
     position:relative;
     object-fit: cover;
     width:200px;
     height:200px;
   }

   .work-title{
     width: 80vw;
   }
.work-header{
  margin-bottom: 0px;
}

  image-wrap{
    display: none;
  }

  .work-image{
    display: none;
  }
  .mountain1{
      display:none;
    }

    .mountain2{
      display:block;
      z-index: 1;
    }

    .bubble{
      margin-top: 75vh;
      width: 40px;
      height: 40px;
      border: 3px solid var(--bubbleColor);
    }

    .App-footer{
      min-height: 30vh;
    }
}

  @media (max-width: 500px){

    .menu{
      display:block;
    }

    .hamburger{
      display:block;
      margin-right: 10px;
      padding-bottom: 30px;
    }

    .hamburger2{
      display:block;
      right:0;
      top:0;
      position: absolute;
      margin-right: 38px;
      margin-top: 32px;
    }
    .name{
      font-size: 2.6rem;
    }
    
    .mountain1{
      display:none;
    }

    .mountain2{
      display:block;
      margin-left: -5000px;
    }
    .work-wrap{
      align-items: center;
      justify-content: center;
    }

    .work-title{
      display:flex;
      flex-direction: column;
      align-items:baseline; 
    }
  
    .work-titles{
      justify-content: space-evenly;
      height: 250vh;
    }
    .image-wrap{
      display: none;
    }
    .name-wrap {
      height: 65vh;
    }
    .mountains{
      width: 2000vw;
      margin-left: 100px;
    }
  
    .work-image2{
      display: block;
      position: relative;
      object-fit: cover;
       width:80vw;
       height:80vw;
       margin-bottom: 30px;
    }
   
    .work-link {
      font-size: 1.5rem;
    }
    .work{
      margin-bottom: 50px;
    }
    
    .bubble{
      width: 40px;
      height: 40px;
      border: 3px solid var(--bubbleColor);
    }
  
    .header-wrap{
      display:none;
    }

    .App-footer{
      display: none;
    }
    .mountain1{
      display:none;
    }

    .mountain2{
      display:block;
    }

}
  
  